
import { defineComponent, onBeforeMount, ref } from 'vue'
import { Fold } from '@element-plus/icons-vue'
import TopMenu from '@/components/menu/TopMenu.vue'
import AsideMenu from '@/components/menu/AsideMenu.vue'
import router from '@/router'

export default defineComponent({
	name: 'DefaultLayout',

	components: {
		AsideMenu,
		TopMenu,
		Fold
	},

	setup() {
		const isActiveAsideMenu = ref(true)

		onBeforeMount(() => {
			if (window.innerWidth < 769) isActiveAsideMenu.value = false
		})

		router.beforeEach(() => {
			if (window.innerWidth < 769) isActiveAsideMenu.value = false
		})

		return {
			isActiveAsideMenu
		}
	}
})
