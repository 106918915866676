import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "default-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsideMenu = _resolveComponent("AsideMenu")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_Fold = _resolveComponent("Fold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_TopMenu = _resolveComponent("TopMenu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_el_aside, { style: {"width":"auto","min-width":"250px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_AsideMenu)
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: "shadow-all-screen shadow-all-screen--hide-before-sm",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isActiveAsideMenu = false))
          })
        ], 512), [
          [_vShow, _ctx.isActiveAsideMenu]
        ]),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  class: "el-icon-open-aside",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isActiveAsideMenu = !_ctx.isActiveAsideMenu)),
                  style: {"cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Fold)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_scrollbar, { "wrap-class": "el-scrollbar-full-height" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TopMenu)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}