
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
	name: 'TopMenu',

	setup() {
		const store = useStore()

		function logout() {
			store.dispatch('auth/logout')
		}

		return {
			logout
		}
	}
})
